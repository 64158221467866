<template>
  <div class="sld_settle_information">
    <div class="sld_information">
      <h3 class="h3_tit">供应商信息</h3>
      <div class="sld_informations">
        <!-- 店铺名称 start -->
        <div class="basics_info_box">
          <div class="basics_info_tit">
            <span class="xin">*</span>
            <span>店铺名称:</span>
          </div>
          <div class="basics_info_inp">
            <input
              type="text"
              name=""
              id=""
              placeholder="请输入店铺名称,最多10个字"
              v-model="store.storeName"
              maxlength="10"
            />
          </div>
        </div>
        <!-- 店铺名称 end -->
        <!-- 店铺等级 start -->
        <div class="basics_info_box basics_info_box_one">
          <div class="basics_info_tit basics_info_tit_one">
            <span class="xin">*</span>
            <span>店铺等级:</span>
          </div>
          <div class="basics_info_tab">
            <div class="info_title">
              <div class="info_title_check"></div>
              <div class="info_title_name">店铺等级</div>
              <div class="info_title_name">可发布商品</div>
              <div class="info_title_name">可推荐商品</div>
              <div class="info_title_name">收费标准</div>
              <div class="info_title_shuo">申请说明</div>
            </div>
            <div
              class="info_td"
              v-for="(item, index) in gradeList.data"
              :key="index"
            >
              <div class="info_td_check">
                <img
                  src="../../../assets/radio_xuan.png"
                  alt=""
                  v-if="item.flag"
                />
                <img
                  src="../../../assets/radio_wei.png"
                  alt=""
                  v-if="!item.flag"
                  @click="grade_radio(index)"
                />
              </div>
              <div class="info_td_name">{{ item.gradeName }}</div>
              <div class="info_td_name">{{ item.goodsLimit }}</div>
              <div class="info_td_name">{{ item.recommendLimit }}</div>
              <div class="info_td_name">{{ item.price }}</div>
              <div class="info_td_shuo">{{ item.description }}</div>
            </div>
          </div>
        </div>
        <!-- 店铺等级 end -->
        <!-- 开店时长 start -->
        <div class="basics_info_box">
          <div class="basics_info_tit">
            <span class="xin">*</span>
            <span>开店时长:</span>
          </div>
          <div class="basics_info_sel sel_info">
            <el-select
              v-model="store.applyYear"
              class="m-2"
              placeholder="请选择开店时长"
              size="large"
            >
              <el-option
                v-for="item in openShopList.data"
                :key="item"
                :label="item + '年'"
                :value="item "
              />
            </el-select>
          </div>
        </div>
        <!-- 开店时长 end -->
        <!-- 经营类型 start -->
        <div class="basics_info_box basics_info_box_one">
          <div class="basics_info_tit basics_info_tit_one">
            <span class="xin">*</span>
            <span>经营类型:</span>
          </div>
          <div class="basics_info_tree">
            <el-tree
              ref="treeRef"
              :default-checked-keys="store.preCateAuditPick"
              node-key="categoryId"
              default-expand-all
              :data="dataTree.data"
              :props="defaultProps"
              :expand-on-click-node="false"
              show-checkbox
              check-on-click-node
              @node-click="cateTree"
            />
          </div>
        </div>
        <!-- 经营类型 end -->
      </div>
      <!-- 下一步按钮 -->
      <div class="agreement_btn" @click="nextStep">提交</div>
    </div>
  </div>
</template>

<script>
import { getCurrentInstance, watchEffect, ref, reactive, onMounted } from "vue";
import { useRouter } from "vue-router";
import { ElMessage } from "element-plus";
import SldCommonEmpty from "@/components/SldCommonEmpty";
export default {
  name: "AccountList",
  components: { SldCommonEmpty },
  setup() {
    const router = useRouter();
    const { proxy } = getCurrentInstance();
    const L = proxy.$getCurLanguage();
    // 开店时长列表
    const openShopList = reactive({ data: [1] });
    const store = reactive({
      storeName: "", //店铺名称
      storeGradeId: "", //店铺等级
      applyYear: "", //开店时长
      goodsCategoryIds: "", //申请分类id字符串
      preCateAuditPick: [], //经营类型选中
      preGoodsCategoryIds: [], //经营类型分类id没有处理过的
    });
    // 开店时长
    const openShop = ref("");
    // 配置选项
    const defaultProps = {
      children: "children",
      label: "categoryName",
      id: "categoryId",
    };
    const treeRef = ref();

    const dataTreeList = reactive({ data: [] });
    // 模拟树状图数据
    const dataTree = reactive({ data: [] });
    // 店铺等级
    const gradeList = reactive({ data: [] });

    //获取开店时长
    const openTime = () => {
      proxy.$get("v3/member/front/member/openTime").then((res) => {
        if (res.state == 200) {
          openShopList.data = res.data;
        } else {
        }
      });
    };

    // 获取经营类型
    const getCateList = () => {
      proxy.$get("v3/member/front/cateAudit/getCateList").then((res) => {
        if (res.state == 200) {
          dataTree.data = res.data;
        } else {
        }
      });
    };

    // 获取店铺类型
    const getGrade = () => {
      proxy.$get("v3/member/front/storeGrade/list").then((res) => {
        if (res.state == 200) {
          res.data.list.forEach((item) => {
            item.flag = false;
          });
          if (res.data.list.length > 0) {
            res.data.list[0].flag = true;
            store.storeGradeId = res.data.list[0].gradeId;
          }
          gradeList.data = res.data.list;
          getDetail();
        } else {
        }
      });
    };

    // 店铺选择
    const grade_radio = (index) => {
      gradeList.data.forEach((item) => {
        item.flag = false;
      });
      gradeList.data[index].flag = true;
      store.storeGradeId = gradeList.data[index].gradeId;
    };

    // 经营类型id获取
    const cateTree = (data, node, ddd) => {
      // let data_o = []
      // let data_t = []
      // data_o.push(data)
      // data_t.push(node)
      // console.log('====================================');

      // console.log('====================================');
      let arr = [];
      if (node.level == 3) {
        arr.push(node.key);
        arr.push(node.parent.key);
        arr.push(node.parent.parent.key);
        arr = arr.reverse();
        if (node.checked == true) {
          dataTreeList.data.push(arr);
        } else {
          for (var i = 0; i < dataTreeList.data.length; i++) {
            let item = dataTreeList.data[i];
            if (item[2] == arr[2]) {
              dataTreeList.data.splice(i, 1);
              break;
            }
          }
        }
      } else if (node.level == 2) {
        node.childNodes.forEach((item) => {
          let arr = [];
          arr.push(item.key);
          arr.push(item.parent.key);
          arr.push(item.parent.parent.key);
          arr = arr.reverse();
          if (item.checked == true) {
            for (var i = 0; i < dataTreeList.data.length; i++) {
              let item = dataTreeList.data[i];
              if (item[2] == arr[2]) {
                dataTreeList.data.splice(i, 1);
                break;
              }
            }
            dataTreeList.data.push(arr);
          } else {
            for (var i = 0; i < dataTreeList.data.length; i++) {
              let item = dataTreeList.data[i];
              if (item[2] == arr[2]) {
                dataTreeList.data.splice(i, 1);
                break;
              }
            }
          }
        });
      } else if (node.level == 1) {
        if (node.checked) {
          node.childNodes.forEach((item) => {
            item.childNodes.forEach((it) => {
              let arr = [];
              arr.push(it.key);
              arr.push(it.parent.key);
              arr.push(it.parent.parent.key);
              arr = arr.reverse();
              if (item.checked == true) {
                for (var i = 0; i < dataTreeList.data.length; i++) {
                  let item = dataTreeList.data[i];
                  if (item[2] == arr[2]) {
                    dataTreeList.data.splice(i, 1);
                    break;
                  }
                }
                dataTreeList.data.push(arr);
              }
            });
          });
        } else {
          for (var i = dataTreeList.data.length - 1; i >= 0; i--) {
            let item = dataTreeList.data[i];
            if (item[0] == node.key) {
              dataTreeList.data.splice(i, 1);
            }
          }
        }
      }
      store.preCateAuditPick = treeRef.value.getCheckedKeys();
    };

    //  下一步按钮事件
    const nextStep = () => {
      if (!store.storeName.trim()) {
        ElMessage.warning("请输入店铺名称，最多10个字");
        return;
      }
      if (store.storeGradeId.length == 0) {
        ElMessage.warning("请选择店铺等级");
        return;
      }
      if (!store.applyYear) {
        ElMessage.warning("请选择开店时长");
        return;
      }
      console.log(dataTreeList.data);
      if (dataTreeList.data.length == 0) {
        ElMessage.warning("请选择经营类型");
        return;
      }
      let grobj = [];
      // console.log(JSON.parse(localStorage.getItem('saveApply1')));
      dataTreeList.data.forEach((item) => {
        grobj.push(item.join("-"));
      });
      store.goodsCategoryIds = grobj.join(",");
      store.preGoodsCategoryIds = dataTreeList.data;
      localStorage.setItem("saveApply2", JSON.stringify(store));
      // router.push({
      //   path: "/member/settle/pmailbox",
      // });


      let saveApply1 = JSON.parse(localStorage.getItem("saveApply1"));
      let saveApply2 = JSON.parse(localStorage.getItem("saveApply2"));
      let saceapply = Object.assign(saveApply1, saveApply2);
      saceapply.vendorExtend.enterpriseBrandJsonList.forEach((item) => {
        if (item.enterpriseBrandIsTime) {
          // item.enterpriseBrandCreateTime = "";
          item.enterpriseBrandEndTime = "";
          item.enterpriseBrandIsTime = 1;
        } else {
          item.enterpriseBrandIsTime = 0;
        }
      });
      saceapply.vendorExtend.enterpriseBrandJsonList = JSON.stringify(
        saceapply.vendorExtend.enterpriseBrandJsonList
      );
      saceapply.preGoodsCategoryIds = JSON.stringify(
        saceapply.preGoodsCategoryIds
      );
      saceapply.preCateAuditPick = JSON.stringify(saceapply.preCateAuditPick);

      let params = {};
      params = saceapply;
      proxy
        .$post("v3/member/front/member/saveApply", params, "application/json")
        .then((res) => {
          if (res.state == 200) {
            ElMessage.success(res.msg)
            localStorage.removeItem('saveApply1')
            localStorage.removeItem('saveApply2')
            router.push({
              path: "/member/settle/results",
            });
            
          } else {
            ElMessage.success(res.msg)
           
          }
        });
    };

    //获取信息
    const getDetail = () => {
      if (JSON.parse(localStorage.getItem("saveApply2"))) {
        for (let i in store) {
          if (i == "preGoodsCategoryIds") {
            dataTreeList.data = JSON.parse(localStorage.getItem("saveApply2"))[
              i
            ];
          } else {
            store[i] = JSON.parse(localStorage.getItem("saveApply2"))[i];
          }
        }
        gradeList.data.forEach((item) => {
          item.flag = false;
        });
        gradeList.data.forEach((item) => {
          if (item.gradeId == store.storeGradeId) {
            item.flag = true;
          }
        });
      } else {
        proxy.$get("v3/member/front/member/applyDetail").then((res) => {
          if (res.state == 200) {
            if(res.data.state){
              store.storeName = res.data.storeName;
              store.storeGradeId = res.data.storeGradeId;
              store.applyYear = res.data.applyYear;
              // store.goodsCategoryIds = res.data.goodsCategoryIds;
              store.preCateAuditPick = JSON.parse(res.data.preCateAuditPick);
              dataTreeList.data = JSON.parse(res.data.preGoodsCategoryIds);
              gradeList.data.forEach((item) => {
                item.flag = false;
              });
              gradeList.data.forEach((item) => {
                if (item.gradeId == store.storeGradeId) {
                  item.flag = true;
                }
              });
  
              if(obj.enterpriseEmail){
                localStorage.setItem('saveApply3',res.data.vendorExtend.enterpriseEmail)
              }

            }
          }
        });
      }
    };
    onMounted(() => {
      openTime();
      getGrade();
      getCateList();
    });

    return {
      L,
      openShopList,
      openShop,
      defaultProps,
      dataTree,
      nextStep,
      getCateList,
      getGrade,
      gradeList,
      grade_radio,
      cateTree,
      treeRef,
      dataTreeList,
      store,
      openTime,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../../../style/member/information.scss";
</style>
<style lang="scss">
.sld_settle_information {
  .sel_info {
    .el-select {
      width: 399px;
    }
  }
  .basics_dizhi {
    .el-select {
      width: 261px;
    }
  }
  .basics_di {
    .el-select {
      margin-right: 20px;
      width: 261px;
    }
  }
  .brand_box {
    .el-input--prefix .el-input__inner {
      height: 30px;
    }
    .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
      width: 211px;
    }
  }
  .brand_box_one {
    display: flex;
    align-items: center;
    .el-input--prefix .el-input__inner {
      height: 30px;
    }
    .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
      width: 148px;
    }
    .el-checkbox {
      margin-left: 13px;
    }
  }

  .el-tree-node__label {
    position: relative;
  }
  .el-tree-node__label:before {
    content: "";
    width: 20px;
    height: 20px;
    display: block;
    position: absolute;
    top: 0px;
    left: -24px;
    z-index: 9;
  }
  .el-checkbox__inner {
    top: 0;
  }
}
</style>
